<template>
	<main class="page">
		<grid-container :columns="1">
			<div slot="cell">
				<h1>
					<span class="highlight">Legal notice</span>
				</h1>

				<h2>
					Pursuant to § 5 of the TMG <br />
					<small>(Telemediengesetz – Tele Media Act by German law)</small>
				</h2>
				<p>
					Kerim Hadri <br />
					Eschersheimer Landstr. 315 <br />
					60320 Frankfurt am Main
				</p>

				<p>
					email: hello@projekt8.net
				</p>

				<h2>Disclaimer</h2>
				<h3>Limitation of liability for internal content</h3>
				<p>
					The content of my website has been compiled with meticulous care and to the best
					of my knowledge. However, I cannot assume any liability for the up-to-dateness,
					completeness or accuracy of any of the pages.
				</p>
				<p>
					Pursuant to section 7, para. 1 of the TMG (Telemediengesetz – Tele Media Act by
					German law), I as service provider am liable for my own content on these pages
					in accordance with general laws. However, pursuant to sections 8 to 10 of the
					TMG, I as service provider am not under obligation to monitor external
					information provided or stored on my website. Once I have become aware of a
					specific infringement of the law, I will immediately remove the content in
					question. Any liability concerning this matter can only be assumed from the
					point in time at which the infringement becomes known to me.
				</p>

				<h3>Limitation of liability for external links</h3>
				<p>
					My website contains links to the websites of third parties („external links“).
					As the content of these websites is not under my control, I cannot assume any
					liability for such external content. In all cases, the provider of information
					of the linked websites is liable for the content and accuracy of the information
					provided. At the point in time when the links were placed, no infringements of
					the law were recognisable to me. As soon as an infringement of the law becomes
					known to me, I will immediately remove the link in question.
				</p>

				<h3>Copyright</h3>
				<p>
					The content and works published on this website are governed by the copyright
					laws of Germany. Any duplication, processing, distribution or any form of
					utilisation beyond the scope of copyright law shall require the prior written
					consent of the author or authors in question.
				</p>

				<h3>Data protection</h3>
				<p>
					A visit to my website can result in the storage of information about the access
					(date, time, page accessed) on my server. This does not represent any analysis
					of personal data (e.g. name, address or e-mail address). If personal data is
					collected, this only occurs – to the extent possible – with the prior consent of
					the user of the website. Any forwarding of the data to third parties without the
					express consent of the user shall not take place.
				</p>
				<p>
					I would like to expressly point out that the transmission of data via the
					Internet (e.g. by e-mail) can offer security vulnerabilities. It is therefore
					impossible to safeguard the data completely against access by third parties. I
					cannot assume any liability for damages arising as a result of such security
					vulnerabilities.
				</p>
				<p>
					The use by third parties of all published contact details for the purpose of
					advertising is expressly excluded. I reserve the right to take legal steps in
					the case of the unsolicited sending of advertising information; e.g. by means of
					spam mail.
				</p>
			</div>
		</grid-container>
	</main>
</template>

<script>
	import GridContainer from '@/components/GridContainer.vue';

	export default {
		name: 'PageLegals',
		components: {
			GridContainer,
		},

		data() {
			return {};
		},
	};
</script>

<style lang="scss" scoped>
	.page {
		grid-template-columns: 100%;
		gap: 0;
		grid-template-areas:
			'title'
			'content';

		@include breakpoint($large) {
			grid-template-columns: calc(60vw + var(--spacing-xxl));
		}
	}

	.content {
		max-width: initial;
	}

	h2 {
		font-size: var(--text-xl);
		margin-top: var(--spacing-xl);
	}

	h3 {
		font-size: var(--text-md);
		margin-top: var(--spacing-lg);
	}
</style>